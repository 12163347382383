import React from "react";
import OwlCarousel from "react-owl-carousel2";
import data from "../data/data.json";

// Opciones para el carrusel
const options = {
  dots: true,
  autoplay: true,
  loop: true,
  autoplayTimeout: 5000,
  margin: 15,
  responsive: {
    0: {
      items: 1,
    },
    480: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1100: {
      items: 3,
    },
  },
};

export function Gallery() {
  const galleryItems = data.Gallery; // Accede a los elementos del JSON

  return (
    <section id="gallery" className="pt100 pb100">
      <div className="container">
      <div className="section-title">
          <div className="section-title">
          <h2 style={{textAlign:'center'}}>Galería</h2>
          <p style={{textAlign:'center'}}>
          Explora nuestra galería de proyectos.
          </p>
        </div>
          </div>
       

        <div className="row">
          <div className="col-sm-12">
            <OwlCarousel
              className="gallery owl-carousel owl-theme"
              options={options}>
              {galleryItems.map((item, index) => (
                <div key={index} className="item">
                  <a href={item.largeImage} title={item.title} target="_blank">
                    <img
                      className="img-fluid"
                      src={item.smallImage}
                      alt={item.title}
                      style={{
                        width: "100%",
                        height: "50%",
                        objectFit: "cover",
                        marginBottom: "20px",
                        
                      }} // Asegura que la imagen se ajuste bien
                      
                    />
                  </a>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
}
